


































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";

import { AttendeeDataObject } from "@/types/interfaces";

import analyticsVuexModule from "@/store/vuex-modules/admin-analytics";
const analyticsStore = getModule(analyticsVuexModule);

import Spinners from "@/components/utilities/Spinners.vue";
import AnalyticsDateControl from "./AnalyticsDateControl.vue";

import { transformDataToCsvUrl } from "@/services/csv";

export default Vue.extend ({
    components: {
        Spinners,
        AnalyticsDateControl
    },
    computed: {
        loading(): boolean {
            return analyticsStore.loading;
        },
    
        loggedIn(): AttendeeDataObject[] {
            return analyticsStore.loggedIn;
        },
    
        notLoggedIn(): AttendeeDataObject[] {
            return analyticsStore.notLoggedIn;
        },
    
        tableHeaders(): string[] {
            return ["ID", "First Name", "Last Name", "Email", "Company Name"];
        },
    
        loggedInCsv(): string {
            return this.getCsvUrl(this.loggedIn);
        },
    
        notLoggedInCsv(): string {
            return this.getCsvUrl(this.notLoggedIn);
        },
    
        hasData(): boolean {
            return Boolean(
                (this.loggedIn && this.loggedIn.length) ||
                    (this.notLoggedIn && this.notLoggedIn.length)
            );
        },
    
        loggedInPercent(): string {
            const total = this.loggedIn.length + this.notLoggedIn.length;
            const percent = ((this.loggedIn.length / total) * 100).toFixed(2);
    
            return percent;
        }

    },
    methods: {
        getAttendeeLoginData() {
            analyticsStore.getAttendeeLoginStatus();
        },
    
        getCsvUrl(items: Array<AttendeeDataObject>) {
            const valuesOnly = items.map((item) => Object.values(item));
            const dataArray = [this.tableHeaders, ...valuesOnly];
    
            return transformDataToCsvUrl(dataArray);
        }

    },

    mounted() {
        if (!this.hasData) {
            this.getAttendeeLoginData();
        }
    }

})
